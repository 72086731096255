<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
        <!-- <el-table-column
          align="center"
          prop="user_id"
          label="用户ID"
        ></el-table-column> -->
        <el-table-column align="center" prop="id" label="ID"></el-table-column>
        <el-table-column
          align="center"
          prop="user.username"
          label="用户名称"
        ></el-table-column>
        
        <el-table-column
          align="center"
          prop="name"
          label="兑换卷标题"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="description"
          label="描述"
        ></el-table-column>
        <!-- <el-table-column align="center"   prop="days" label="有效天数"></el-table-column> -->
        <el-table-column align="center" label="类型">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.type == 1">兑换卷</div>
              <div v-else>商品兑换卷</div>
              <!-- <el-select v-model="valueType" @change="changeValue" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option> 
            </el-select>-->
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="count" width="100px"  prop="discount" label="数量"></el-table-column> -->
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <!-- <el-tooltip class="item" effect="dark"  content="修改兑换卷" placement="top-start">
            <el-button icon="el-icon-edit" type="primary" @click="updataExp(scope.row)" ></el-button>
          </el-tooltip> -->
            <el-tooltip
              class="item"
              effect="dark"
              content="取消兑换卷"
              placement="top-start"
            >
              <el-button
                icon="el-icon-delete"
                type="danger"
                @click="delExp(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
    <!-- 删除兑换卷模态框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>是否确定取消该兑换卷?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refuse">取 消</el-button>
        <el-button type="primary" @click="consent">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  PostExchangeList,
  PostExchangeCancel,
} from "@/api/Expchange/ExpList.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //删除兑换卷模态框
      dialogVisible: false,
      //家庭注销模态框
      dialogVisible: false,
      openState: true,
      dialogFalimyID: "",
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      valueType: "",
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [],
      //家庭Id
      ExpID: "", //家庭ID
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //删除兑换卷
    delExp(id) {
      this.ExpID = id;
      console.log(id);
      this.dialogVisible = true;
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.PostExchangeList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data = {
        page: this.getInfo.page_code,
        list_num: this.getInfo.page_num,
      };
      console.log(data);
      this.tableData = [];
      this.PostExchangeList(data);
    },
    //取消删除兑换卷
    refuse() {
      this.dialogVisible = false;
      console.log("取消删除兑换卷");
    },
    //同意删除兑换卷
    consent() {
      this.$confirm("确认删除？")
        .then((_) => {
          console.log("确定");
          var data = {
            id: this.ExpID,
          };
          console.log(data);
          this.PostExchangeCancel(data);
          this.dialogVisible = false;
        })
        .catch(() => {
          console.log("取消");
          this.dialogVisible = false;
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    /**-----------------网络请求生命周期函数--------------------- */
    //取消兑换卷
    PostExchangeCancel(data) {
      let res = PostExchangeCancel(data);
      res
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 1) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            var data = {
              page: this.getInfo.page_code,
              list_num: this.getInfo.page_num,
            };
            this.tableData = [];
            this.PostExchangeList(data);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //兑换卷设置
    async PostExchangeList(data) {
      let res = await PostExchangeList(data);
      if (res.data.code == 1) {
        this.tableData = this.tableData.concat(res.data.data.data);
      } else {
        this.$message.error(res.data.message);
      }
      console.log(res.data);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data = {
      page: this.getInfo.page_code,
      list_num: this.getInfo.page_num,
    };
    this.PostExchangeList(data);
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>